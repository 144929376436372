import _colorConvert from "color-convert";
var exports = {};
var module = {
  exports: exports
};
const colorConvert = _colorConvert;

const wrapAnsi16 = (fn, offset) => function () {
  const code = fn.apply(colorConvert, arguments);
  return `\u001B[${code + offset}m`;
};

const wrapAnsi256 = (fn, offset) => function () {
  const code = fn.apply(colorConvert, arguments);
  return `\u001B[${38 + offset};5;${code}m`;
};

const wrapAnsi16m = (fn, offset) => function () {
  const rgb = fn.apply(colorConvert, arguments);
  return `\u001B[${38 + offset};2;${rgb[0]};${rgb[1]};${rgb[2]}m`;
};

function assembleStyles() {
  const codes = new Map();
  const styles = {
    modifier: {
      reset: [0, 0],
      // 21 isn't widely supported and 22 does the same thing
      bold: [1, 22],
      dim: [2, 22],
      italic: [3, 23],
      underline: [4, 24],
      inverse: [7, 27],
      hidden: [8, 28],
      strikethrough: [9, 29]
    },
    color: {
      black: [30, 39],
      red: [31, 39],
      green: [32, 39],
      yellow: [33, 39],
      blue: [34, 39],
      magenta: [35, 39],
      cyan: [36, 39],
      white: [37, 39],
      gray: [90, 39],
      // Bright color
      redBright: [91, 39],
      greenBright: [92, 39],
      yellowBright: [93, 39],
      blueBright: [94, 39],
      magentaBright: [95, 39],
      cyanBright: [96, 39],
      whiteBright: [97, 39]
    },
    bgColor: {
      bgBlack: [40, 49],
      bgRed: [41, 49],
      bgGreen: [42, 49],
      bgYellow: [43, 49],
      bgBlue: [44, 49],
      bgMagenta: [45, 49],
      bgCyan: [46, 49],
      bgWhite: [47, 49],
      // Bright color
      bgBlackBright: [100, 49],
      bgRedBright: [101, 49],
      bgGreenBright: [102, 49],
      bgYellowBright: [103, 49],
      bgBlueBright: [104, 49],
      bgMagentaBright: [105, 49],
      bgCyanBright: [106, 49],
      bgWhiteBright: [107, 49]
    }
  }; // Fix humans

  styles.color.grey = styles.color.gray;

  for (const groupName of Object.keys(styles)) {
    const group = styles[groupName];

    for (const styleName of Object.keys(group)) {
      const style = group[styleName];
      styles[styleName] = {
        open: `\u001B[${style[0]}m`,
        close: `\u001B[${style[1]}m`
      };
      group[styleName] = styles[styleName];
      codes.set(style[0], style[1]);
    }

    Object.defineProperty(styles, groupName, {
      value: group,
      enumerable: false
    });
    Object.defineProperty(styles, "codes", {
      value: codes,
      enumerable: false
    });
  }

  const ansi2ansi = n => n;

  const rgb2rgb = (r, g, b) => [r, g, b];

  styles.color.close = "\x1B[39m";
  styles.bgColor.close = "\x1B[49m";
  styles.color.ansi = {
    ansi: wrapAnsi16(ansi2ansi, 0)
  };
  styles.color.ansi256 = {
    ansi256: wrapAnsi256(ansi2ansi, 0)
  };
  styles.color.ansi16m = {
    rgb: wrapAnsi16m(rgb2rgb, 0)
  };
  styles.bgColor.ansi = {
    ansi: wrapAnsi16(ansi2ansi, 10)
  };
  styles.bgColor.ansi256 = {
    ansi256: wrapAnsi256(ansi2ansi, 10)
  };
  styles.bgColor.ansi16m = {
    rgb: wrapAnsi16m(rgb2rgb, 10)
  };

  for (let key of Object.keys(colorConvert)) {
    if (typeof colorConvert[key] !== "object") {
      continue;
    }

    const suite = colorConvert[key];

    if (key === "ansi16") {
      key = "ansi";
    }

    if ("ansi16" in suite) {
      styles.color.ansi[key] = wrapAnsi16(suite.ansi16, 0);
      styles.bgColor.ansi[key] = wrapAnsi16(suite.ansi16, 10);
    }

    if ("ansi256" in suite) {
      styles.color.ansi256[key] = wrapAnsi256(suite.ansi256, 0);
      styles.bgColor.ansi256[key] = wrapAnsi256(suite.ansi256, 10);
    }

    if ("rgb" in suite) {
      styles.color.ansi16m[key] = wrapAnsi16m(suite.rgb, 0);
      styles.bgColor.ansi16m[key] = wrapAnsi16m(suite.rgb, 10);
    }
  }

  return styles;
} // Make the export immutable


Object.defineProperty(module, "exports", {
  enumerable: true,
  get: assembleStyles
});
export default module.exports;